#page-container.packages {
  .title {
    font-weight: 600;
    font-size: 2.25rem;
  }

  .scroll {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: visible;
    padding: 2rem 0;

    &::-webkit-scrollbar {
      display: none;
    }

    .package-card {
      flex: 0 0 auto;
    }

    &.has-selection {
      .package-card {
        opacity: 0.6;
        transform: scale(0.975);

        &.selected {
          opacity: 1;
          transform: scale(1.025);
        }
      }
    }
  }
}
