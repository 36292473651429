.package-card {
  width: 13rem;
  flex: 0 0 auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.15);

  .image {
    width: 100%;
    padding-top: 100%;
    background-size: cover;
    background-position: center center;
  }

  .title {
    position: absolute;
    top: 0;
    left: 0;
  }

  .selected-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;

    .inner {
      position: relative;
      background: white;
      border-radius: 100%;
      width: 1.75rem;
      height: 1.75rem;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.15);

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 600;
      }
    }
  }

  .description {
    margin: 1rem 0;
  }

  .price {
    font-weight: 600;
    color: black;
    display: block;

    strong {
      font-size: 1.75em;
    }
  }

  .consultation-count {
    display: block;
    margin-top: -0.25rem;
  }
}
