.ion-page#projects {
  ion-content.has-projects {
    --background: var(--alt-background-color);
  }

  #page-container {
    padding: 0;
    min-height: calc(100% - var(--offset-top));

    &.no-projects {
      .page-title span {
        color: var(--ion-color-secondary);
        display: block;
      }

      .top .new-project-button-container .new-project-button {
        padding-top: 150%;
      }
    }

    .top {
      background: white;
      padding-top: 1rem;
      padding-bottom: 1.5rem;
    }

    .page-title {
      margin: 0 0 0 1.5rem;
    }

    h3 {
      color: var(--ion-color-secondary);
      margin: 0 0 0 1.5rem;
    }

    .new-project-button-container {
      margin: 1rem 1.5rem 0 1.5rem;
      width: calc(100% - 3rem);
      max-width: 30rem;

      .new-project-button {
        margin: 0;
        padding: 100% 0 0 0;
        background: url("/assets/images/home-page-bg-img.jpg");
        background-size: cover;
        background-position: top left;

        h1 {
          margin: 0;
          position: absolute;
          top: 2rem;
          left: 2rem;
          color: white;
          font-size: 2.125rem;
          text-shadow: 0 0 3px rgba(0, 0, 0, 0.05);

          span {
            opacity: 0.8;
          }
        }
      }
    }

    .regular-projects-container {
      padding-top: 1rem;

      .horiz-scroll-container {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        overflow-x: scroll;
        padding: 1rem 0 1rem 1.5rem;
        margin: 0;
      }

      .project-preview-card {
        margin: 0 1.5rem 0 0;
        padding: 1rem;
        width: 16rem;
        flex: 0 0 auto;

        .inner {
          display: flex;
          flex-wrap: nowrap;

          .new {
            align-self: center;
            margin-left: -1rem;
          }

          .image {
            flex: 0 1 auto;
            margin-right: 0.5rem;

            ion-thumbnail {
              --size: 6rem;
            }
          }

          .meta {
            .name {
              font-weight: 600;
            }

            p {
              margin: 0;
              font-size: 0.9em;
            }
          }
        }
      }
    }

    .admin-projects-container {
      background: transparent;
      padding: 1rem;

      h1 {
        margin: 0.5rem 0 0 1rem;
      }
    }

    .admin-project-card {
      position: relative;

      .new-indicator {
        position: absolute;
        top: 0.375rem;
        left: 0.375rem;
      }

      .images {
        margin-bottom: 1rem;

        .thumb {
          display: inline-block;
          margin-right: 0.5rem;
        }
      }
    }
  }
}
