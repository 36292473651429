.project-details {
  overflow: visible;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;

    .name {
      margin-right: 1rem;
      margin-top: 0.5rem;
      font-size: 1rem;
      font-weight: 600;
    }

    .questionnaire {
      transform: scale(0.75) translate(12.5%, -12.5%);
    }
  }

  .description {
    margin-top: 0;
    color: var(--alt-text-color);
    font-size: 0.825rem;
  }
}
