#page-container.more-consultations {
  .title {
    font-weight: 600;
    font-size: 2.25rem;
    margin: 0;
  }

  .scroll {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: visible;

    &::-webkit-scrollbar {
      display: none;
    }

    .package-card {
      flex: 0 0 auto;
    }

    &.has-selection {
      .package-card {
        opacity: 0.6;
        transform: scale(0.975);

        &.selected {
          opacity: 1;
          transform: scale(1.025);
        }
      }
    }
  }

  .selected-package {
    padding: 1rem;

    p {
      margin-top: 0;
      font-size: 0.875rem;
      text-align: center;
      color: var(--ion-color-secondary);
    }

    .container {
      padding: 0 1rem;
    }
  }
}
