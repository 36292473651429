#page-container.consultation {
  padding: 0 0 0.425rem 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  .close-consultation {
    position: absolute;
  }

  .project-meta {
    flex: none;
    padding: 1rem 1rem 0 1rem;
  }

  .messages-container {
    overflow-y: scroll;
    border-top: 1px solid gainsboro;
  }
}
