.generic-modal {
  color: black;
}

.consultation-name-modal {
  .modal-wrapper {
    border-radius: 5px;
    height: 180px;
  }
}
