#page-container.home {
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: url("/assets/images/home-page-bg-img.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left;

  .page-top {
    flex: 1 1 auto;
    position: relative;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    .logo {
      position: absolute;
      z-index: 0;
      width: 3rem;
      height: 3rem;
      top: 2rem;
      left: 2rem;
      border-radius: 0.625rem;
      overflow: hidden;
      opacity: 0;
    }
  }

  .form,
  .email-verification {
    z-index: 1;
    background: white;
    flex: none;
    padding: 0 2rem 2rem 2rem;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    h1 {
      background: white;
      margin: 2rem 0 0 0;
      line-height: 1.4;

      .lead {
        color: var(--alt-text-color);
      }
    }

    .create-an-account {
      width: 100%;
      text-align: center;
    }

    form {
      background: white;

      ion-list {
        padding: 0 0.5rem;

        ion-list-header,
        ion-item {
          --inner-padding-start: 0;
          --inner-padding-end: 0;
          --ion-safe-area-left: 0;
          --ion-safe-area-right: 0;
        }

        ion-input {
          border-bottom: 1px solid lightgray;

          &.has-focus {
            border-color: var(--ion-color-primary);
          }
        }
      }

      .pw-tooltip {
        font-size: 0.666em;
      }
    }

    .terms {
      width: 100%;
      text-align: center;
      font-size: 0.666em;

      a {
        display: inline-block;
      }
    }

    .back-button {
      display: block;
      flex: none;

      ion-icon {
        transform: scale(1.5) translateX(25%);
      }
    }
  }

  .form.reset-password.email-sent {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    form {
      margin-top: 2rem;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .phoneNum {
    --highlight-color-focused: #eb445a !important;
    --highlight-color-invalid: #eb445a !important;
    border-bottom:1px solid lightgrey !important;
  }
  
  .phoneInput {
    border-bottom:1px solid transparent !important;
  }

  .phoneLabel {
    font-size:11px !important;
  }

  .phoneNumInput {
    width: 20px !important;
    min-width: 20px !important;
    max-width: 20px !important;
  }

  .focused {
    color: #eb445a;
  }

  .smsCheckbox {
    margin-right:5px;
  }

  .smsCheckboxLabel {
    font-size: 12px;
    color:#000000;
  }

  @media (max-width:481px) {
    .smsCheckbox {
      height:17px !important;
      width: 31px !important;
    }
  }
}
