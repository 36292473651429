.thumbnail-gallery {
  margin-bottom: 1rem;

  .thumb {
    display: inline-block;
    margin: 0 0.5rem 0.5rem 0;
    border-radius: 0.625rem;

    ion-thumbnail {
      --size: 4rem;
    }
  }
}

.enlarge-image {
  --ion-toolbar-background: transparent;
  --ion-color-dark: white;

  .modal-wrapper {
    background: rgba(0, 0, 0, 0.75);
  }

  .image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
