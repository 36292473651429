.horizontal-thumbnail-gallery {
  width: calc(100% + 1rem);
  max-width: calc(100% + 1rem);
  padding: 1rem;
  margin-left: -1rem;
  overflow-x: scroll;

  .inner {
    display: flex;
    flex-wrap: nowrap;
  }

  .thumb {
    margin: 0 1rem 0 0;
    flex: none;

    ion-thumbnail {
      --size: 5rem;
    }
  }
}

.enlarge-image {
  --ion-toolbar-background: transparent;
  --ion-color-dark: white;

  .modal-wrapper {
    background: rgba(0, 0, 0, 0.75);
  }

  .image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
