#consultation-messages {
  background: var(--alt-background-color);

  .inner {
    padding-top: 1rem;
  }

  .no-messages {
    display: block;
    padding: 2rem 0;
    width: 66.6%;
    margin: auto;
    font-size: 0.75rem;
    color: var(--alt-text-color);
  }

  .day-date {
    color: var(--alt-text-color);
    display: block;
    padding: 0.25rem;
    font-size: 0.625rem;
    width: 100%;
    text-align: right;

    &.by-admin {
      text-align: left;
    }
  }

  .message-row {
    padding: 0.125rem;
    display: flex;

    &:first-child {
      padding-top: 1rem;
    }

    &:last-of-type {
      padding-bottom: 1rem;
    }

    &.sent {
      flex-direction: row-reverse;
    }

    &.received {
      .message-bubble,
      .message-header {
        background: var(--ion-color-primary);
        color: white;
      }
    }

    .message-bubble {
      padding: 0.5rem;
      border-radius: 0.625rem;
      background: white;
      color: black;
      display: inline-block;
      max-width: 75%;
      min-width: 50%;
      font-size: 0.75rem;
      margin: 0;

      .message-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.25rem;

        .name,
        .time {
          font-size: 0.625rem;
        }
      }

      p {
        margin: 0;
      }
    }
  }

  form {
    border-top: 1px solid gainsboro;
    background: white;
    padding-bottom: 0.5rem;
  }

  .empty-state {
    width: 100%;
    font-size: 0.75rem;
    line-height: 1 !important;
  }

  .controls {
    display: flex;
    align-items: stretch;
    width: 100%;
    padding-top: 0.5rem;

    .input-area {
      width: 100%;
      display: flex;
      align-items: stretch;
      border-radius: 0.625rem;
      background: var(--alt-background-color);
      margin-right: 0.5rem;

      ion-textarea {
        padding-left: 10px;
        font-size: 0.825rem;

        textarea {
          padding-right: 0;
        }
      }

      ion-button.send {
        display: block;
        height: 56px;
        margin-top: 0;
        margin-bottom: 0;
        --background: transparent;
        --background-activated: rgba(0, 0, 0, 0.2);
        --background-focused: rgba(0, 0, 0, 0.1);
        --background-hover: rgba(0, 0, 0, 0.1);

        i {
          color: black;
        }
      }
    }

    ion-button.images {
      display: block;
      height: 56px;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0.4rem;
      margin-right: 0.5rem;
    }
  }

  .close-consultation-container {
    margin-top: 7px;
    margin-left: 3px;
    margin-right: 3px;
  }
}
