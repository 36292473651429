.images-modal-with-gallery {
  color: black;

  h6 {
    padding-left: 0.5rem;
  }

  .inner {
    padding: 1rem 1rem 0 1rem;
  }

  ion-card {
    display: inline-block;
    margin: 0;

    .thumb {
      --size: 144px;
    }
  }

  .trigger-buttons {
    text-align: center;
  }

  .has-selection {
    .not-selected {
      opacity: 0.333;
    }

    .selected-for-deletion {
      border: 1px solid var(--ion-color-tertiary);
    }
  }
}
