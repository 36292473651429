/* global css vars

  usage:
  ```
    .class-name {
      property-name: var(${var_name});
    }
  ```
*/

:root {
  --card-radius: 40px;
}

.app {
  font-family: eina;
}
