.bottom-drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.15s all cubic-bezier(0.36, 0.66, 0.04, 1);
  background: rgba(0, 0, 0, 0.3);
  z-index: -1;
  opacity: 0;

  &.is-open {
    z-index: 10;
    opacity: 1;

    .inner .content {
      transform: none;
    }
  }

  .inner {
    position: relative;
    height: 100%;
    width: 100%;
    top: 0;

    .content {
      position: absolute;
      width: 100%;
      bottom: 0;
      background: white;
      padding: 1rem 0;
      transform: translateY(100%);
      transition: 0.3s all;
      border-radius: 0.625rem;

      .close-bar {
        width: 2rem;
        height: 0.25rem;
        border-radius: 0.625rem;
        background: lightgray;
        position: absolute;
        top: 0.375rem;
        left: 50%;
        transform: translateX((-50%));
      }
    }
  }
}
