#page-container.project {
  .consultation-count {
    font-size: 0.75rem;

    .count {
      font-size: 1rem;
    }
  }

  .consultations-used {
    margin-left: 0;
    margin-right: 0;
    background: var(--alt-background-color);

    .card-header {
      padding: 1rem;

      .flex-container {
        display: flex;
        align-items: center;

        .icon-container {
          padding: 0.5rem 0.5rem 0.25rem 0.5rem;
          background: var(--ion-color-tertiary);
          border-radius: 0.625rem;
          margin-right: 1rem;

          ion-icon {
            font-size: 1.5rem;
            color: var(--ion-color-primary);
          }
        }
      }
    }

    .card-content {
      text-align: center;
    }
  }

  ion-item {
    font-size: 0.825rem;

    ion-text {
      &.not-new {
        padding-left: 1rem;
      }
    }
  }

  ion-list {
    margin-left: -0.5rem;
    margin-right: 0.5rem;
  }

  ion-list-header {
    &.open-consultations {
      color: var(--ion-color-primary);
    }
    &.closed-consultations {
      color: var(--ion-color-secondary);
    }
  }

  .empty-state {
    color: var(--ion-color-secondary);
  }

  strong.time {
    font-weight: 500;
    margin-left: 0.175rem;
  }
}

.enlarge-image {
  --ion-toolbar-background: transparent;
  --ion-color-dark: white;

  .modal-wrapper {
    background: rgba(0, 0, 0, 0.75);
  }

  .image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

ion-toast.new-project-toast {
  --color: var(--ion-color-primary);
  --background: var(--ion-color-tertiary);
}

ion-icon.new-indicator {
  transform: scale(0.5);
  color: #5fc9f8;
}

.newConsulation{
  --padding-start: 10px !important;
}
