#page-container.questionnaire {
  .field {
    font-size: 0.825rem;
  }

  .phone-field {
    .format-tooltip {
      font-size: 0.666em;
      --color: var(--alt-text-color);
    }
  }
}

ion-toast.questionnaire-toast {
  --color: var(--ion-color-primary);
  --background: var(--ion-color-tertiary);
}

ion-label.questionnaire-question {
  font-weight: 500;
}
